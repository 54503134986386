@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300&family=Source+Code+Pro:wght@300;400;500;600&display=swap');

@layer base {
  :root {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --black-100: #16171b;
    --white: white;
    --red: #ff4d4d;
    --blue-2: #92bee7;
    --purple-100: #866cc7;
    --black-95: #222326;
    --qualified: #3cb652;
    --black-40: #a2a2a4;
    --white-5: rgba(255, 255, 255, 0.05);
    --white-10: rgba(255, 255, 255, 0.1);
    --orange: #f29871;
    --black-80: #454549;
    --black-90: #2d2e32;
    --white-2-21: rgba(255, 255, 255, 0.02);
    --rank-yellow: #edb513;
    --cool-red: #f271a7;
    --white-20: rgba(255, 255, 255, 0.2);
    --black-50: #8a8b8d;
    --rank-blue: #1f87e7;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-family: 'Source Code Pro', monospace;
    background-color: var(--black-100);
    color: var(--white);
    background-image: url("https://assets-global.website-files.com/62e3ee10882dc50bcae8d07a/631a5d4631d4c55a475f3e34_noise-50.png");
    background-position: 0 0;
    background-size: 200px 200px;
    /* font-family: IBM Plex Sans, sans-serif; */
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    min-height: 100%;
  }
}

.header_background-image-wrapper {
  z-index: -1;
  mix-blend-mode: hard-light;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;

  .header_background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
}

.height-screen {
  height: calc(100vh - 68px);
}

.tidot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 50px;
  display: inline-block;
  height: 8px;
  margin-right: 4px;
  width: 8px;
}


@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px)
  }

  28% {
    transform: translateY(-5px)
  }

  44% {
    transform: translateY(0px)
  }
}

.tidot:nth-child(1) {
  animation-delay: 200ms;
}

.tidot:nth-child(2) {
  animation-delay: 300ms;
}

.tidot:nth-child(3) {
  animation-delay: 400ms;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}